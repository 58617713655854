<template>
  <div class="login-container">
    <div class="loginbox">
      <div class="loginlogo">
        <img class="logo1" src="/pic/logo1.png" alt="">
      </div>
      <div class="loginnav">
        <div :class="{ 'itemmenu': true, 'currentmenu': item.isSelect }" v-for="item in loginnav" :key="item.id">
          {{ item.name }}</div>
      </div>
     
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    loginnav: {
      get() {
        return this.$store.state.loginnav
      }
    }
  },

  mounted() {

  },

  methods: {},
};
</script>

<style lang="scss" scoped>
// .userinfobox {
//   height: 20px;
//   width: 20px;
//   background: red;
//   position: relative;
//   top: 10px;
//   right: 10px;
// }

.login-container {
  background: #fff;
  width: 100%;
  background: #fff;
  height: 60px;

  .loginbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0 10%;
    display: flex;

    .loginlogo {
      height: 55px;
      width: 196px;

      .logo1 {
        width: 196px;
        height: 55px;
      }
    }

    .loginnav {
      display: flex;

      .itemmenu {
        width: 100px;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;

      }

      .currentmenu {
        color: #FFFFFF;
        background: #28A7E1;
      }
    }
  }
}
</style>