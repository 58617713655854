<template>
  <div class="bindtelphone">
    <login-header></login-header>
    <div>
      <div class="bindtitle">绑定手机号</div>

      <div class="action">
        <el-form
          class="form"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
        >

          <el-form-item
            class="inputitem"
            prop="telphone"
            :rules="rules.telphone"
            label="手机号"
          >
            <el-input
              placeholder="请输入手机号码"
              v-model="ruleForm.telphone"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="code"
            class="inputitem"
            :rules="rules.code"
            label="验证码"
          >
            <el-input
              placeholder="短信验证码"
              type="code"
              v-model="ruleForm.code"
            >
              <i slot="suffix">
                <div class="inputslot">
                  <!-- <div class='line'></div> -->
                  <div
                    class="msgBtn"
                    v-if="timer===0"
                    @click.prevent="getMsgCode"
                  >获取验证码</div>
                  <div
                    class="msgBtn"
                    v-else
                  > {{timer}}秒内输入</div>
                </div>

              </i>
            </el-input>
          </el-form-item>
          <el-form-item
            label=" "
            class="inputitem"
          >
            <el-button
              type="primary"
              class="btnsubmit"
              @click="submitForm('ruleForm')"
            >提 交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import loginHeader from "@/components/login/loginheader";
import { scanBind ,getTelphoneMsgCode} from "@/api/login";
export default {
  components: { loginHeader },
  data() {
    return {
      timer: 0,
      ruleForm: {
        telphone: "",
        password: "",
      },
      rules: {
        telphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          //验证手机号
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],

        code: [
          {
            required: true,
            min: 4,
            max: 4,
            message: "验证码格式不正确",
            traggers: "blur",
          },
        ],
      },
    };
  },

	computed:{
		membersWechatScanId:{
			get(){
				return this.$store.state.membersWechatScanId
			},
			set(v){
				this.$store.commit('setMembersWechatScanId', v);
			}
		},
		membersId:{
			get(){
				return this.$store.state.membersId
			},
			set(v){
				this.$store.commit('setMembersId', v);
			}
		},
	},
  mounted() {},

  methods: {
    getMsgCode() {
      //验证手机号码格式
      var that = this;
      this.$refs.ruleForm.validateField("telphone", function (valid) {
        if (!valid) {
          const params = {
            phone: that.ruleForm.telphone,
          };
          getTelphoneMsgCode(params).then((res) => {
            //发送成功后的处理
            that.timer = 120;
            const timerAction = setInterval(function () {
              if (that.timer > 0) {
                that.timer--;
              } else {
                clearInterval(timerAction);
              }
            }, 1000);
          });
        }
      });
    },

    changePCMode(v) {
      this.loginMode = v;
    },

    submitForm() {
      var that = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          const params = {
            members_wechat_scan_id: that.membersWechatScanId, //密码登录
            phone: that.ruleForm.telphone,
            code: that.ruleForm.code,
          };
          scanBind(params).then((res) => {
      
						if(res.data.profile){
                 //登录成功
              const token = res.data.token;
              that.$store.commit('setToken',token)
							//登录成功
							// const token = res.data.token
              that.$router.push('/')
						}else{
							//完善信息获取要完善信息的id
							that.membersId = res.data.members_id
							that.$router.push('/bindmemberinfo')
						}
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bindtelphone {
  // height: 100vh;
  width: 100%;
  background: #f0f3f8;
}
.bindtitle {
  width: 80%;
  height: 50px;
  background: #ffffff;
  line-height: 50px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}
.action {
  width: 80%;
  height: 232px;
  background: #ffffff;
  margin: 0 auto;
  padding-top: 10px;
  padding-left: 5px;
  box-sizing: border-box;
  .form {
    .inputitem {
      width: 420px;
      margin-top: 20px;
      text-align: left;
    }
    .btnsubmit {
      width: 120px;
    }
  }
}

.inputslot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85px;
  .msgBtn {
    width: 80px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #28a7e1;
    cursor: pointer;
  }
  .line {
    height: 14px;
    width: 1px;
    background: #c5c5c5;
  }
}
</style>